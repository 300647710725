export default function () {
  const largeScale = ref(false)

  onMounted(() => {
    getZoomLevel()
    window.addEventListener('resize', () => {
      getZoomLevel()
    })
  })

  function getZoomLevel() {
    // check the browser zoom level then update largeScale boolean value
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100)
    largeScale.value = browserZoomLevel >= 375
  }

  return { largeScale }
}
